const staff = {
  PROD: {
    authority:
      "https://sa.auth.thyra.telstra.com/SecureAuth674/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.auth.thyra.telstra.com/SecureAuth674",
    },
    client_id: "49a64c0dcc644961b4af629d10597d3b",
    client_secret:
      "61c5f42c09fb754c118a657ea529af71e4c7f7a8172bb1d5337552524ab5e216",
    redirect_uri: "https://tapas.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas.telstra.com",
  },
  STAGING: {
    authority:
      "https://sa.auth.thyra.telstra.com/SecureAuth674/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.auth.thyra.telstra.com/SecureAuth674",
    },
    client_id: "49a64c0dcc644961b4af629d10597d3b",
    client_secret:
      "61c5f42c09fb754c118a657ea529af71e4c7f7a8172bb1d5337552524ab5e216",
    redirect_uri: "https://tapas-staging.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-staging.telstra.com",
  },
  SIT: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-sit.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-sit.telstra.com",
  },
  SVT: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-svt.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-svt.telstra.com",
  },
  QA: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-qa.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-qa.telstra.com",
  },
  MOCK: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-mock.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-mock.telstra.com",
  },
  DEV: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-dev.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-dev.telstra.com",
  },
  POC: {
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    metadata: {
      issuer: "https://sa.thyra.telstra.com/SecureAuth872",
    },
    client_id: "110b5e059328429aaa4211e98b4c6672",
    client_secret:
      "f02c716c8830ef44c9ada6102aad7f3c257c1352d453851afb508744262bbc99",
    redirect_uri: "https://tapas-poc.telstra.com",
    response_type: "code",
    automaticSilentRenew: false,
    post_logout_redirect_uri: "https://tapas-poc.telstra.com",
  },
};

export { staff };

const partner = {
    SIT: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-sit.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-sit.telstra.com",
    },
    SVT: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-svt.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-svt.telstra.com",
    },
    QA: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-qa.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-qa.telstra.com",
    },
    MOCK: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-mock.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-mock.telstra.com",
    },
    DEV: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-dev.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-dev.telstra.com",
    },
    POC: {
      authority: "https://sa.thyra.telstra.com/SecureAuth871/SecureAuth.aspx",
      metadata: {
        issuer: "https://sa.thyra.telstra.com/SecureAuth871",
      },
      client_id: "9cbc1c552c90411faa9b26d69d682074",
      client_secret:
        "fb7dcd53607db04a9331c2d674e11044659b1229161d5395d5bead78a2fde479",
      redirect_uri: "https://tapas-poc.telstra.com",
      response_type: "code",
      automaticSilentRenew: false,
      post_logout_redirect_uri: "https://tapas-poc.telstra.com",
    },
  };
  
  
  export { partner };
  